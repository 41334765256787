<template>
  <div class="dialog-container">
    <el-dialog
      title="审批"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol')" prop="wrapperCol">
              <!-- <el-input clearable v-model="form.leaveType"></el-input> -->
              <span>{{form.leaveType}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_title')" prop="materialName">
              <!-- <el-input clearable v-model="form.title"></el-input> -->
              <span>{{form.title}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_description')" prop="wrapperCol_description">
              <el-input clearable type="textarea" disabled rows="3" v-model="form.description"></el-input>
              <!-- <span>{{form.description}}</span> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_startDate')" prop="wrapperCol_startDate">
              <!-- <el-input clearable v-model="form.startDate"></el-input> -->
              <span>{{form.startDate}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_duration')" prop="wrapperCol_duration">
              <!-- <el-input clearable v-model="form.duration"></el-input> -->
              <span>{{form.duration}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_comment')" prop="comment">
              <el-input type="textarea" :rows="3" clearable v-model="form.comment"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent(2)">同意</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="sureEvent(3)">驳回</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent(result) {
      console.log("sureEvent -> result", this.form)
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
          if (valid) {
            this.$http.post(this.isEdit ? apis.MaterialInfo_UpdateAsync : apis.taskAudit, {
              ...this.form,
              result,
              // procName: this.form.processName,
            }).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("保存成功！")
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     *  taskId: record.id,
            procInstId: record.procInstId,
            procName: record.processDefName,
            businessKey: record.businessKey,
            procDefKey: record.processDefKey,
            applyer: record.applyer
     */
    getDataQuery(record) {
      // if (this.isEdit) {
        this.form.taskId = record.id
        this.form.procInstId = record.procInstId
        this.form.procName = record.processDefName
        this.form.businessKey = record.businessKey
        this.form.procDefKey = record.processDefKey
        this.form.applyer = record.applyer
        console.log("getDataQuery -> this.form.taskId", this.form)
        // 在这里获取回显的数据
        this.$http.get(`${apis.leave}/${record.businessKey}`).then((res) => {
          if (res.data.code === 0) {
            this.form = {
              ...this.form,
              description: res.data.data.description,
              duration: res.data.data.duration,
              endDate: res.data.data.endDate,
              startDate: res.data.data.startDate,
              title: res.data.data.title,
              id: res.data.data.id,
              type: res.data.data.type,
            }
            console.log("getDataQuery -> res.data.data", res.data.data, this.form)
          }
        })
      // }
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
